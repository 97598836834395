<template>
  <div class="login-container">
    <h2 class="title"><i class="icon-lock-open-1"></i>{{ $t('user.loginTitle') }}</h2>
    <form @submit.prevent>
      <div class="form-item">
        <input v-model="user.email" :placeholder="$t('user.email')" autocomplete="off" type="text"/>
        <transition name="fade-in">
          <p class="error-tips" v-if="errorTips.email">{{ errorTips.email }}</p>
        </transition>
      </div>
      <div class="form-item">
        <input v-model="user.password" :placeholder="$t('user.password')" autocomplete="off" type="password"/>
        <transition name="fade-in">
          <p class="error-tips" v-if="errorTips.password">{{ errorTips.password }}</p>
        </transition>
      </div>
      <div class="form-item">
        <Turnstile ref="turnstile" size="flexible"/>
        <transition name="fade-in">
          <p v-if="errorTips.turnstile_response" class="error-tips">{{ errorTips.turnstile_response }}</p>
        </transition>
      </div>
      <div class="form-item">
        <button class="button long" @click="submit">{{ $t('user.login') }}</button>
      </div>
      <div class="form-item">
        <a-tooltip placement="bottom" arrowPointAtCenter overlayClassName="custom-tooltip">
          <template slot="title">
            <p v-if="locale === 'zh_CN'">登录遇到困难可以<a class="contact" href="javascript:;"
                                                            @click="handleContact">联系客服</a>，或者通过微信/电报解决。
            </p>
            <p v-else>
              If you have difficulty logging in, you can <a class="contact" href="https://t.me/Blockcc_bc"
                                                            target="_blank">click here</a>, or find us
              <span class="contact">Blockcc_bc</span> on
              telegram.
            </p>
          </template>
          <span style="cursor: pointer">{{ $t('user.cannotLogin') }}</span>
        </a-tooltip>
        <router-link :to="{ name: 'account-checkEmail' }" class="handle-btn pull-right">
          {{ $t('user.forgetPassword') }}
        </router-link>
      </div>
      <div class="form-item" style="margin-top: 100px">
        <p class="text-center">
          {{ $t('user.noAccount') }}
          <router-link :to="{ name: 'register' }" class="handle-btn primary" style="margin-left: 5px">
            {{ $t('user.registerNow') }}
          </router-link>
        </p>
      </div>
    </form>
    <check-phone-modal :modalShowAble="modalShowAble" @changeModalData="changeModalData"
                       @userUpdated=""></check-phone-modal>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import schema from 'async-validator';
import Turnstile from '../components/Turnstile';
import {active, login} from '../services/user';
import CheckPhoneModal from 'app/views/checkPhone';

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const BLACK_NAME = ['register', 'account-checkEmail', 'account-changePassword', 'login', 'email-active', 'redirect'];
export default {
  name: 'login',
  computed: {
    ...mapGetters(['userInfo', 'locale']),
  },
  components: {
    CheckPhoneModal,
    Turnstile,
  },
  data() {
    const descriptor = {
      email: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.enterEmail'),
        },
        {
          pattern: emailReg,
          message: this.$t('user.enterLegalEmail'),
        },
      ],
      password: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.enterPassword'),
        },
        {
          validator: (rule, value) => value.length >= 6,
          message: this.$t('user.enterLegalPassword'),
        },
      ],
      turnstile_response: [
        {
          type: 'string',
          required: true,
          message: this.$t('user.confirmAliCode'),
        },
      ],
    };
    return {
      user: {
        email: '',
        password: '',
        turnstile_response: '',
      },
      validator: new schema(descriptor),
      errorTips: {
        email: '',
        password: '',
        turnstile_response: '',
      },
      modalShowAble: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name && !BLACK_NAME.includes(from.name)) {
        vm.$router.push({name: 'login', query: {redirect_url: from.fullPath}});
      }
    });
  },
  mounted() {
  },
  methods: {
    async submit() {
      this.user.turnstile_response = await this.$refs.turnstile.getResponse();
      this.validator
          .validate(this.user)
          .then(async () => {
            await this.$store.dispatch('initAuth', true);
            login(this.user)
                .then(async res => {
                  await this.$store.dispatch('initAuth', true);
                  const userInfo = this.userInfo;
                  console.log(userInfo);
                  if (userInfo && userInfo.source === this.locale) {
                    if (userInfo.registeredTime && userInfo.registeredTime > 1609148034599 && (!userInfo.phone || !userInfo.phoneCheck) && this.locale === 'zh_CN') {
                      this.modalShowAble = true;
                    } else {
                      location.href = this.$route.query.redirect_url || '/';
                    }
                  } else {
                    await this.$store.dispatch('logout');
                    location.href = '/redirect';
                  }
                })
                .catch(err => {
                  if (err.response.status === 403 && err.response.data.errorKey === 'error.account.unactivated') {
                    this.$error({
                      title: this.$t('error["error.account.loginWithoutActivated"]'),
                    });
                    active({email: this.user.email});
                  } else if (err.response.status === 403 && err.response.data.errorKey === 'error.account.unauthorized') {
                    this.$error({
                      title: this.$t('error["error.account.userInfo"]'),
                    });
                  } else {
                    this.$error({
                      title: this.$t(`error['${err.response.data.errorKey}']`),
                    });
                  }
                  this.$refs.turnstile.reset();
                });
          })
          .catch(({errors, fields}) => {
            let errorTips = {};
            if (!errors) return;
            errors.forEach(error => {
              if (!errorTips[error.field]) {
                errorTips[error.field] = error.message;
              }
            });
            this.errorTips = errorTips;
          });
    },
    handleContact() {
      document.querySelector('#meiqia-btn').click();
    },
    changeModalData(data, redirect) {
      this.modalShowAble = data !== 'false';
      // if (redirect && redirect === 'false') {
      //     this.$store.dispatch('logout');
      //
      // }
      // if (redirect && redirect === 'true') {
      if (!this.modalShowAble) {
        location.href = this.$route.query.redirect_url || '/';
      }
      // }
    },
  },
};
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';

.login-container {
  width: 500px;
  margin: 60px auto;
  // border: 1px solid #f1f1f1;
  border-radius: 3px;

  h2 {
    font-size: 20px;
    padding: 20px 40px;
    margin-bottom: 20px;
    text-align: center;

    i {
      margin-right: 10px;
    }
  }
}

.form-item {
  padding: 0 40px;
  margin-bottom: 20px;
  position: relative;

  input {
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
    height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;

    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px white inset !important;
    }

    &:focus {
      border-color: @primary-color;
    }
  }

  .error-tips {
    position: absolute;
    left: 40px;
    font-size: 12px;
    padding-top: 4px;
    color: @red-color;
  }
}

.handle-btn {
  margin-bottom: 10px;

  &:hover,
  &.primary {
    color: @blue-color;
  }
}

.long {
  width: 100%;
  margin: 0;
}

.button {
  margin: 0;
}

.contact {
  color: @primary-color;
}
</style>
